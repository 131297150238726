const StorageService = {
    setItem: (key, value) => {
        try {
            if (typeof value !== 'string' && typeof value !== 'number' && typeof value !== 'boolean' && !Array.isArray(value) && typeof value !== 'object') {
                throw new Error('Invalid value type');
            }
            localStorage.setItem(key, JSON.stringify(value));
        } catch (error) {
            console.error(`Error setting item ${key}: ${error.message}`);
        }
    },

    getItem: (key, defaultValue = null) => {
        try {
            const value = localStorage.getItem(key);
            return value ? JSON.parse(value) : defaultValue;
        } catch (error) {
            console.error(`Error getting item ${key}: ${error.message}`);
            return defaultValue;
        }
    },

    hasItem: (key) => {
        try {
            return localStorage.getItem(key) !== null;
        } catch (error) {
            console.error(`Error checking item ${key}: ${error.message}`);
            return false;
        }
    },

    removeItem: (key) => {
        try {
            localStorage.removeItem(key);
        } catch (error) {
            console.error(`Error removing item ${key}: ${error.message}`);
        }
    },

    clear: () => {
        try {
            localStorage.clear();
        } catch (error) {
            console.error(`Error clearing localStorage: ${error.message}`);
        }
    }
};

export default StorageService;