import React from "react";

export default function Loading() {
    return (
        <div className="Loading PageSingle">
            <section className="AppContainer">
                <h1>Loading</h1>
            </section>
        </div>
    );
}
