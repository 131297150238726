import React from "react";
import { Flex, Col, Row } from "antd";
import { Link } from "react-router-dom";
import { ReactComponent as Facebook } from "../assets/icons/Facebook.svg";
import { ReactComponent as Instagram } from "../assets/icons/Instagram.svg";
import { ReactComponent as Twitter } from "../assets/icons/Twitter.svg";
import { ReactComponent as Youtube } from "../assets/icons/Youtube.svg";
import StorageService from "../utils/storage";
import { footerMenuQuery } from "../utils/query";
import { useQuery } from "@apollo/client";

export default function AppFooter() {
    const organisation = StorageService.getItem("organisation");
    const logo = StorageService.getItem("logo");

    const { data } = useQuery(footerMenuQuery, {
        variables: {
            id: StorageService.getItem("organisationBusableId"),
        },
    });

    return (
        <footer className="AppFooter">
            <div className="SiteFooter">
                <div className="AppContainer">
                    <Row>
                        <Col lg={9}>
                            <Flex justify="space-between" gap="20px" vertical>
                                <Link to="/">
                                    <img src={logo} height={50} alt="" />
                                </Link>
                                <p>{organisation?.WebsiteBuilder?.bio}</p>
                                <ul className="SocialIcons">
                                    {organisation?.WebsiteBuilder?.facebook_url && (
                                        <li>
                                            <Link to={organisation?.WebsiteBuilder?.facebook_url} target="_blank">
                                                <Facebook />
                                            </Link>
                                        </li>
                                    )}
                                    {organisation?.WebsiteBuilder?.instagram_url && (
                                        <li>
                                            <Link to={organisation?.WebsiteBuilder?.instagram_url} target="_blank">
                                                <Instagram />
                                            </Link>
                                        </li>
                                    )}
                                    {organisation?.WebsiteBuilder?.twitter_url && (
                                        <li>
                                            <Link to={organisation?.WebsiteBuilder?.twitter_url} target="_blank">
                                                <Twitter />
                                            </Link>
                                        </li>
                                    )}
                                    {organisation?.WebsiteBuilder?.youtube_url && (
                                        <li>
                                            <Link to={organisation?.WebsiteBuilder?.youtube_url} target="_blank">
                                                <Youtube />
                                            </Link>
                                        </li>
                                    )}
                                </ul>
                            </Flex>
                        </Col>
                        <Col lg={15}>
                            <Row>
                                {data?.wbMenus?.data[0]?.attributes?.menu?.map((menu) => (
                                    <Col key={menu.id} xs={12} lg={6}>
                                        <div className="FooterLinks">
                                            {!menu.hideName && <h4>{menu.name}</h4>}
                                            <ul>
                                                {menu?.menu?.map((submenu) => (
                                                    <li key={submenu.id}>
                                                        <Link to={submenu.url} target={submenu.url.startsWith("/") ? "_self" : "_blank"}>
                                                            {submenu.name}
                                                        </Link>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    </Col>
                                ))}
                            </Row>
                        </Col>
                    </Row>
                </div>
            </div>
            <div className="CopyFooter">
                <div className="AppContainer">
                    <small>
                        &copy; Copyright 2024 Ogden's Coaches Pty Ltd. All rights reserved. <Link to="/privacy">Privacy policy</Link>
                    </small>
                    <small>
                        Website powered by{" "}
                        <a href="https://busable.app" target="_blank" rel="noreferrer">
                            Busable
                        </a>
                    </small>
                </div>
            </div>
        </footer>
    );
}
