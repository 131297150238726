import React, { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";

// Import your components here
import { gql, useQuery } from "@apollo/client";
import StorageService from "../utils/storage";
import Home from "../pages/Home";
import EventsSingle from "../pages/EventsSingle";
import Privacy from "../pages/Privacy";
import TwoColumnWithSidebar from "../pages/TwoColumnWithSidebar";
import TwoColumnWithForm from "../pages/TwoColumnWithForm";
import TwoColumn from "../pages/TwoColumn";
import OneColumnWithMap from "../pages/OneColumnWithMap";
import TwoColumnWithFormAndSidebar from "../pages/TwoColumnWithFormAndSidebar";
import Regions from "../pages/Regions";
import Townlink from "../pages/Townlink";
import OgdensOrana from "../pages/OgdensOrana";
import OgdensWestern from "../pages/OgdensWestern";
import OgdensMudgee from "../pages/OgdensMudgee";
import OgdensCondobolin from "../pages/OgdensCondobolin";
import Policies from "../pages/Policies";
import Sitemap from "../pages/Sitemap";
import Links from "../pages/Links";
import StudentTravel from "../pages/StudentTravel";
import DynamicList from "../pages/DynamicList";
import NotFound from "../pages/NotFound";
import Loading from "../pages/Loading";
import TimetablesRedirect from "../components/TimetableRedirect";

const menuQuery = gql`
    query getMenu($id: String) {
        wbMenus(filters: { organisation: { orgId: { eq: $id } } }) {
            data {
                id
                attributes {
                    menu {
                        ... on ComponentWbMenusMenu {
                            name
                            url
                            page {
                                data {
                                    id
                                    attributes {
                                        type {
                                            __typename
                                        }
                                    }
                                }
                            }
                        }
                        ... on ComponentWbMenusSubmenu {
                            name
                            url
                            page {
                                data {
                                    id
                                    attributes {
                                        type {
                                            __typename
                                        }
                                    }
                                }
                            }
                            menu {
                                name
                                url
                                page {
                                    data {
                                        id
                                        attributes {
                                            type {
                                                __typename
                                            }
                                        }
                                    }
                                }
                                menu {
                                    name
                                    url
                                    page {
                                        data {
                                            id
                                            attributes {
                                                type {
                                                    __typename
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;

const AppRouter = () => {
    const [routes, setRoutes] = useState([]);
    const { data } = useQuery(menuQuery, {
        variables: {
            id: StorageService.getItem("organisationBusableId"),
        },
    });

    useEffect(() => {
        if (data) {
            // const menu = data.wbMenus.data[0].attributes.menu;
            const menu = [];
            data.wbMenus.data.forEach((item) => item.attributes.menu.forEach((subItem) => menu.push(subItem)));
            // generate flat map of all routes with urls defined (children including)
            const items = [];

            const handleDynamicPage = (item) => {
                items.push(
                    ...[
                        {
                            url: item.url,
                            type: "dynamicList",
                            pageId: item.page?.data?.id,
                        },
                        {
                            url: `${item.url}/:id`,
                            type: "dynamicItem",
                            pageId: item.page?.data?.id,
                        },
                    ]
                );
            };
            menu.forEach((item) => {
                if (item.__typename === "ComponentWbMenusMenu") {
                    const type = item.page?.data?.attributes?.type[0]?.__typename;
                    if (type === "ComponentLayoutsPosts") {
                        handleDynamicPage(item);
                    } else {
                        items.push({
                            url: item.url,
                            type,
                            pageId: item.page?.data?.id,
                        });
                    }
                } else if (item.__typename === "ComponentWbMenusSubmenu") {
                    return item.menu.map((subItem) => {
                        const type = subItem.page?.data?.attributes?.type[0]?.__typename;

                        if (subItem.url) {
                            if (type === "ComponentLayoutsPosts") {
                                handleDynamicPage(subItem);
                            } else {
                                items.push({
                                    url: subItem.url,
                                    type,
                                    pageId: subItem.page?.data?.id,
                                });
                            }
                        }
                        subItem.menu.map((subSubItem) => {
                            const type = subSubItem.page?.data?.attributes?.type[0]?.__typename;
                            if (subSubItem.url) {
                                if (type === "ComponentLayoutsPosts") {
                                    handleDynamicPage(subSubItem);
                                } else {
                                    items.push({
                                        url: subSubItem.url,
                                        type,
                                        pageId: subSubItem.page?.data?.id,
                                    });
                                }
                            }
                        });
                    });
                }
            });
            setRoutes(items.filter(e => e.pageId !== undefined && e.pageId !== null));
        }
    }, [data]);

    const getElementByType = (type, pageId, url) => {
        switch (type) {
            case "ComponentLayoutsTwoColumnWithFormAndSidebar":
                return <TwoColumnWithFormAndSidebar pageId={pageId} />;
            case "ComponentLayoutsTwoColumnWithForm":
                return <TwoColumnWithForm pageId={pageId} />;
            case "ComponentLayoutsTwoColumn":
                return <TwoColumn pageId={pageId} />;
            case "ComponentLayoutsTwoColumnWithSidebar":
                return <TwoColumnWithSidebar pageId={pageId} />;
            case "ComponentLayoutsOneColumnWithMap":
                return <OneColumnWithMap pageId={pageId} />;
            case "ComponentLayoutsHomepage":
                return <Home pageId={pageId} />;
            case "dynamicList":
                return <DynamicList pageId={pageId} url={url} />;
            case "dynamicItem":
                return <EventsSingle />;
            default:
                return <NotFound />;
        }
    };

    // Hard coded routes that cant be dynamically generated for now.
    const skipGenerationArray = [
        "/regions",
        "/town-link",
        "/timetables",
        "/trip-planner",
        "/schools",
        "/ogdens_orana",
        "/ogdens_western",
        "/ogdens_mudgee",
        "/ogdens_condobolin",
        "/privacy",
        "/policies",
        "/sitemap",
        "/links",
        "/student-travel-guidelines",
    ];

    return (
        <Routes>
            {routes
                .filter((r) => !skipGenerationArray.includes(r.url))
                .map((route) => {
                    return (
                        <Route path={route.url} element={getElementByType(route.type, route.pageId, route.url)} exact={route.url === "/"} />
                    );
                })}
            <Route path="/regions" element={<Regions />} />
            <Route path="/timetables" element={<Regions />} />
            <Route path="/trip-planner" element={<Regions />} />
            <Route path="/schools" element={<Regions />} />
            <Route path="/town-link" element={<Townlink />} />
            <Route path="/timetables/:dynamicField" element={<TimetablesRedirect />} />
            <Route path="/ogdens_orana" element={<OgdensOrana />} />
            <Route path="/ogdens_western" element={<OgdensWestern />} />
            <Route path="/ogdens_mudgee" element={<OgdensMudgee />} />
            <Route path="/ogdens_condobolin" element={<OgdensCondobolin />} />
            <Route path="/privacy" element={<Privacy />} />
            <Route path="/policies" element={<Policies />} />
            <Route path="/sitemap" element={<Sitemap />} />
            <Route path="/links" element={<Links />} />
            <Route path="/student-travel-guidelines" element={<StudentTravel />} />
            {routes.length ? <Route path="*" element={<NotFound />} /> : <Route path="*" element={<Loading />} />}
        </Routes>
    );
};

export default AppRouter;
