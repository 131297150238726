import React from "react";
import { Link } from "react-router-dom";
import { Flex } from "antd";
import MenuBar from "./MenuBar";
import AppLogo from "../assets/images/ogdens_logo.png";
import AppLogoLight from "../assets/images/ogdens_logo_light.png";
import "./AppHeader.css";
import { useQuery } from "@apollo/client";
import { headerMenuQuery } from "../utils/query";
import StorageService from "../utils/storage";

export default function AppHeader() {
    const logo = StorageService.getItem("logo");
    const { loading, error, data } = useQuery(headerMenuQuery, {
        variables: {
            id: StorageService.getItem("organisationBusableId"),
        },
    });

    return (
        <div className="AppHeader">
            <div className="AppContainer">
                <Flex justify="space-between" align="center">
                    <Link to="/" className="AppLogo">
                        <img src={AppLogo} height={50} alt="" />
                        <img src={AppLogoLight} height={50} alt="" className="AppLogoLight" style={{display: 'none'}} />
                    </Link>
                    <MenuBar data={data} />
                </Flex>
            </div>
        </div>
    );
}
