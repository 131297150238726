import React, { useState } from "react";
import { Button, Card, Form, Input, Select, Alert } from "antd";
import { sendMail } from "../utils/mail";
import StorageService from "../utils/storage";

export const ContactForm = () => {
    const [show, setShow] = useState(false);

    const onSubmit = async (values) => {
        setShow(false);

        const organisation = StorageService.getItem("organisation");
        const to = organisation?.email || 'avindu+wb@busable.app';

        await sendMail({
            to,
            from: "Busable <hello@busable.app>",
            subject: `New Contact Us Enquiry - ${values.email}`,
            merge: {
                ...values,
                email_id: values.email,
            },
            template: "Contact and Feedback",
        });
        setShow(true);
    };
    return (
        <Card bordered={false} className="CardItem mb-25">
            {show === true &&
                <Alert closable onClose={() => setShow(false)} message="Your Contact Request has been sent successfully." type="success" style={{marginBottom: '30px'}} />
            }
            <Form
                labelCol={{
                    span: 8,
                }}
                wrapperCol={{
                    span: 24,
                }}
                layout="horizontal"
                size="large"
                style={{
                    maxWidth: 600,
                }}
                onFinish={onSubmit}
            >
                <Form.Item 
                    label="Name" 
                    name="name"
                    rules={[{ required: true, message: 'Please input your name!' }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item 
                    label="Email" 
                    name="email"
                    rules={[{ required: true, message: 'Please input your email!' }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item 
                    label="Phone" 
                    name="phone"
                    rules={[{ required: true, message: 'Please input your phone number!' }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item 
                    label="Message" 
                    name="message"
                    rules={[{ required: true, message: 'Please input your message!' }]}
                >
                    <Input.TextArea />
                </Form.Item>
                <Form.Item wrapperCol={{ md: { offset: 8, span: 16 } }}>
                    <Button htmlType="submit" size="small" className="btn-secondary">
                        Submit
                    </Button>
                </Form.Item>
            </Form>
        </Card>
    );
};