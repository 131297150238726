import React, { useEffect } from 'react';
import { Col, Row, Card, Button, Collapse, Alert } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';

const Image1 = require('../assets/original/_DA_8653_med.jpg');

const items = [
  {
    key: '2',
    label: "Who can apply?",
    children: <div><p>To be eligible for free school travel, students may need to live a minimum distance from their school:</p><p>Years K-2 (Infants)</p><p>There's no minimum distance.</p><p>Years 3-6 (Primary)</p><p>1.6km straight line distance or 2.3km walking.</p><p>Years 7-12 (Secondary)</p><p>2.0km straight line distance or 2.9km walking.</p></div>
  },
  {
    key: '3',
    label: "Who needs to apply",
    children: <div><p>A new application is only required if the student has never been approved for free school travel before.</p><p>However, you need to update student details online if the student:</p>
    <ul>
      <li>Is moving from Year 2 to Year 3</li>
      <li>Is moving from Year 6 to Year 7</li>
      <li>Is changing schools or campuses</li>
      <li>Is changing address</li>
    </ul></div>
  },
  {
    key: '4',
    label: "How to update your details",
    children: <div><p>If the student has already been approved for school travel and their address, school or name changes, or the student is entering Years 3 or 7, you will need to update your details at:</p><p><a href="https://ssts-apply.transport.nsw.gov.au/ApplySSTS/UpdateEntitlement.html" target="_blank" rel="noreferrer">https://ssts-apply.transport.nsw.gov.au/ApplySSTS/UpdateEntitlement.html</a></p></div>
  },
  {
    key: '5',
    label: "How to apply for free travel for the first time",
    children: <div><h5 className="mb-10 mt-0">Step 1</h5><p>Once you have confirmed your 2024 school enrolment, complete the online application form at <a href="http://transportnsw.info/school-students" target="_blank" rel="noreferrer">transportnsw.info/school-students</a>, nominating your home address and which bus company or transport operator the student needs to travel between home and school.</p>
    <h5 className="mb-10">Step 2</h5><p>Print, sign and date the completed application, then return it to the student's school for endorsement.</p><p>If your child's school is participating in the online management of student transport records, no form print option will be available as the application will be submitted via the website.</p>
    <h5 className="mb-10">Step 3</h5><p>The school then sends the endorsed application to Transport for NSW. You will receive an email confirming the outcome of your application. If the application is approved, Transport for NSW will inform your local bus or transport operator who may send a school travel pass (where applicable) to the student's school, or in some cases will contact you directly about travel arrangements.</p><p>A parent or guardian must apply for students 15 years and under. Students 16 years and over must apply for themselves.</p></div>
  }
];

const itemsfaq = [
  {
    key: '1',
    label: "Can I still apply using a paper form?",
    children: <div><p>Carbon copy forms are no longer being accepted after Term 2, 2016. To download a paper form visit <a href="http://apps.transport.nsw.gov.au/ssts" target="_blank" rel="noreferrer">http://apps.transport.nsw.gov.au/ssts</a>.</p></div>
  },
  {
    key: '2',
    label: "How can I apply if I don't have internet access?",
    children: <div><p>Call <a href="tel:131500">131 500</a> for assistance with your application.</p></div>
  },
  {
    key: '3',
    label: "Does the approved free travel include travel outside school hours?",
    children: <div><p>Approved free school travel is only for travel to and from school. So for travel after hours, on weekends or during school holidays, students will need to purchase a ticket.</p></div>
  },
  {
    key: '4',
    label: "What if there's no public transport in my area?",
    children: <div><p>In areas where there is no public transport,eligible NSW residents may receive a subsidy for transporting the student to school by private vehicle. For details visit <a href="http://transportnsw.info/school-students" target="_blank" rel="noreferrer">http://transportnsw.info/school-students</a></p></div>
  },
  {
    key: '5',
    label: "How do I apply if the student is in a shared parenting situation and needs to travel from two addresses?",
    children: <div><p>Each parent (or the student if aged 16 or over) needs to make a separate application for each address. The same eligibility criteria apply.</p></div>
  },
  {
    key: '6',
    label: "Want to know more?",
    children: <div><p>For more information call <a href="tel:131500">131 500</a> or visit <a href="http://transportnsw.info/school-students" target="_blank" rel="noreferrer">http://transportnsw.info/school-students</a>.</p><p>For help in your language, call the Translating and Interpreting Service (TIS) on <a href="tel:131450">131 450</a>.</p></div>
  }
]

export default function StudentTravel() {
  useEffect(() => {
    document.body.classList.add("PageStudentTravel");
    return () => {
      document.body.classList.remove("PageStudentTravel");
    };
  }, []);
  return (
    <div className="StudentTravel PageSingle" style={{paddingBottom: "50px"}}>
      <section className="PageHero">
        <div className="AppContainer">
          <h1 className="title">Student Travel Guidelines</h1>
        </div>
      </section>
      <div className="AppContainer">
        <Row gutter={[20, 0]}>
          <Col xs={{span: 24, order: 2}} md={{span: 16, order: 1}}>
            <Card bordered={false} className="CardItem mb-25">
              <h2 className="CardTitle">Student Behaviour Guidelines</h2>
              <p>The Code of Conduct applies to all school children enrolled in a government or non-government school, whether they use a government or private bus, and irrespective of whether they travel under the School Student Transport Scheme.</p>
              <Button href="https://www.transport.nsw.gov.au/news-and-events/reports-and-publications/guidelines-for-managing-school-student-behaviour-on-buses" className="mt-20" icon={<DownloadOutlined />} target="_blank">Student Behaviour Guidelines</Button>
            </Card>
            <Card bordered={false} className="CardItem">
              <h2 className="CardTitle">Student Travel Passes</h2>
              <Alert message="Applications are now open for student travel passes for 2024." type="success" className="mb-30" />
              <p>The School Student Transport Scheme (SSTS) provides eligible school students with free or subsidised travel on public transport between home and school, on trains, buses, ferries and long distance coach services.</p>
              <p>The scheme is administered by Transport NSW, and applications for passes are made via the Transport NSW website or by calling <a href="tel:131500">131 500</a>:</p>
              <Button href="https://ssts-apply.transport.nsw.gov.au/ApplySSTS/ApplySSTS.html" className="mt-25 mr-20" target="_blank">Apply for a New Pass</Button>
              <Button href="https://ssts-apply.transport.nsw.gov.au/ApplySSTS/UpdateEntitlement.html" className="mt-25" target="_blank">Update Existing Details</Button>

              <h3 className="mt-50 mb-15">Information</h3>
              <Collapse accordion items={items} size="large" />

              <h3 className="mt-50 mb-15">Frequently Asked Questions</h3>
              <Collapse accordion items={itemsfaq} size="large" />
            </Card>
          </Col>
          <Col xs={{span: 24, order: 1}} md={{span: 8, order: 2}}>
            <Card bordered={false} className="CardItem Cover" bodyStyle={{padding: 0}} cover={<img alt="" src={Image1} />}></Card>
          </Col>
        </Row>
      </div>
    </div>
  )
}
