import React from "react";
import { Link } from "react-router-dom";
import { Col, Row, Card, Button } from "antd";
import { getImageURL } from "../utils/images";

export default function DynamicItems({ data, url }) {
    return (
        <Row gutter={[40, 40]}>
            {data.wbPosts.data.map((post) => (
                <Col key={post.id} md={8}>
                    <Card bordered={false} className="CardItem">
                        <Link to={`${url}/${post.id}`} className="ant-card-cover">
                            <img src={`${getImageURL(post.attributes.cover.data?.attributes.url)}`} alt="" />
                        </Link>
                        <h3>{post.attributes.title}</h3>
                        <p>{post.attributes.summary}</p>
                        <Button href={`${url}/${post.id}`} size="small">
                            Read More
                        </Button>
                    </Card>
                </Col>
            ))}
            {data.wbPosts.data.length === 0 ? (
                <Col md={24} style={{ display: "flex", justifyContent: "center" }}>
                    <span className="InfoText">There are currently no new events available.</span>
                </Col>
            ) : null}
        </Row>
    );
}
