import React from "react";
import { Link } from "react-router-dom";
import { Col, Row, Card, Button } from "antd";
import { useQuery, gql } from "@apollo/client";
import StorageService from "../utils/storage";
import { getImageURL } from "../utils/images";
import { EventsWithCategory, EventsWithoutCategory } from "../utils/query";

export default function EventItems({ category }) {
    const { loading, error, data } = useQuery(category ? EventsWithCategory : EventsWithoutCategory, {
        variables: {
            id: StorageService.getItem("organisationBusableId"),
            categoryId: category || null,
        },
    });

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error :(</p>;

    return (
        <Row gutter={[40, 40]}>
            {data?.wbPosts?.data.map((event) => (
                <Col key={event.id} md={8}>
                    <Card bordered={false} className="CardItem">
                        <Link to={`/events/${event.id}`} className="ant-card-cover">
                            <img src={`${getImageURL(event.attributes.cover.data?.attributes.url)}`} />
                        </Link>
                        <h3>{event.attributes.title}</h3>
                        <p>{event.attributes.summary}</p>
                        <Button href={`/events/${event.id}`} size="small">
                            Read More
                        </Button>
                    </Card>
                </Col>
            ))}
            {data?.wbPosts?.data?.length === 0 ? (
                <Col md={24} style={{ display: "flex", justifyContent: "center" }}>
                    <span className="InfoText">There are currently no new events available.</span>
                </Col>
            ) : null}
        </Row>
    );
}
