import { BrowserRouter as Router } from "react-router-dom";
import { ApolloClient, InMemoryCache, ApolloProvider } from "@apollo/client";
import AppHeader from "./components/AppHeader";
import AppFooter from "./components/AppFooter";
import "./App.css";
import OrganizationFetcher from "./components/OrganizationFetcher";
import AppRouter from "./routes";
import ScrollToTop from "./components/ScrollToTop";
import ReactGA from "react-ga4";
import { hotjar } from 'react-hotjar';
import { useEffect } from "react";

// apollo client
const client = new ApolloClient({
    uri: `${process.env.REACT_APP_BACKEND_URL}/graphql`,
    cache: new InMemoryCache(),
});

function App() {
useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
        ReactGA.initialize("G-Y2GZT77GXP");
        hotjar.initialize({
            id: 4998157,
            sv: 6
        });
        console.log('Initialized GA tracking and Hotjar')
    }
}, []);
    
    return (
        <Router>
            <ScrollToTop />
            <ApolloProvider client={client}>
                <OrganizationFetcher />
                <div className="App">
                    <AppHeader />
                    <AppRouter />
                    <AppFooter />
                </div>
            </ApolloProvider>
        </Router>
    );
}
export default App;
