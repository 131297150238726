import React, { useEffect } from 'react';
import { Col, Row, Card } from 'antd';

const Image1 = require('../assets/original/_DA_8692_med.jpg');

export default function Privacy() {
  useEffect(() => {
    document.body.classList.add("PagePrivacy");
    return () => {
      document.body.classList.remove("PagePrivacy");
    };
  }, []);
  return (
    <div className="Privacy PageSingle" style={{paddingBottom: "50px"}}>
      <section className="PageHero">
        <div className="AppContainer">
          <h1 className="title">Privacy Policy</h1>
        </div>
      </section>
      <div className="AppContainer">
        <Row gutter={[20, 0]}>
          <Col xs={{span: 24, order: 2}} md={{span: 16, order: 1}}>
            <Card bordered={false} className="CardItem">
              <h2 className="CardTitle">Respecting Your Privacy and the Law</h2>
              <p>The privacy of your personal information has always been important to us at Ogden's Coaches. We are committed to respecting your right to privacy and protecting your personal information. We are bound by the National Privacy Principles in the Privacy Act 1988 (Commonwealth) as well as other applicable laws and codes affecting your personal information. Our staff are trained to respect your privacy in accordance with our standards, policies and procedures.</p>

              <h2>About this Privacy Policy</h2>
              <p>As a company offering a wide range of bus and charter services to customers, we collect and hold a range of personal information from and about people (particularly people who use our services, or provide feedback on our services).</p>
              <p>This Privacy Policy outlines how we manage your personal information. It also describes the sorts of personal information generally held, for what purposes, and how that information is collected, held, used and disclosed.</p>

              <h2>Collecting your Personal Information</h2>
              <p>If you are a user of services provided by us, we will collect and hold your personal information for the purposes of:</p>
              <ul>
                <li>Providing you with any relevant information (which may include assessing School Student Transport Scheme applications and identifying you)</li>
                <li>Managing and administering the service</li>
              </ul>
              <p>The information collected will be the minimum required to execute the service you require and will include your name, postal address, date of birth, or other information the NSW Ministry of Transport endorses or considers necessary.</p>
              <p>We will, if it is reasonable or practicable to do so, collect your personal information from you. This may happen when you fill out an application or an administrative form (eg, a change of address form) or when you give us personal information over the telephone or counter, or through our website (eg, when making charter enquiries or bookings).</p>
              <p>In certain cases we collect your personal information from third parties. This might occur, for example where it is important for us to obtain personal information from a school. Customers also provide us with information through submitting travel applications, providing feedback, or booking services etc.</p>

              <h2>Using and Disclosing your Personal Information</h2>
              <p>In line with modern businesses we may disclose your personal information to the organisations described below. Where your personal information is disclosed we will seek to ensure that the information is held, used or disclosed consistently with the National Privacy Principles and other applicable privacy laws and codes.</p>
              <h3>The relevant organisations are those:</h3>
              <ul>
                <li>Involved in maintaining, reviewing and developing our business systems, procedures and infrastructure including testing or upgrading our computer systems,</li>
                <li>Involved in the payments system, including financial institutions, merchants and payment organisations,</li>
                <li>NSW Ministry of Transport for contractual matters,</li>
                <li>As required or authorised by law, for example, to government or regulatory bodies for purposes related to public health or safety, the prevention or detection of unlawful activities, or to protect public revenue,</li>
                <li>Where you have given your consent.</li>
              </ul>

              <h2>Keeping your Personal Information Accurate and Up-To-Date</h2>
              <p>We aim to make sure that the personal information we collect, use or disclose is accurate, complete and up-to-date. We will take reasonable steps to make sure this is the case. This way we can provide you with better service. If you believe your personal information is not accurate, complete or up-to-date, please contact us.</p>

              <h2>Protecting your Personal Information</h2>
              <p>We store information in different ways, including in paper and electronic form. The security of your personal information is important to us and we take reasonable steps to protect it from misuse and loss and unauthorised access, modification or disclosure. Some of the ways we do this are:</p>
              <ul>
                <li>Confidentiality requirements for our employees</li>
                <li>Document storage security policies</li>
                <li>Security measures for systems access</li>
                <li>Providing a discreet environment for confidential discussions</li>
                <li>Only allowing access to personal information where the individual seeking access has satisfied our identification requirements</li>
                <li>Access control for our buildings</li>
              </ul>

              <h2>Your Privacy on the Internet</h2>

              <h2>Our Web Sites</h2>
              <p>We take care to ensure that the personal information you give us on our web sites is protected. For example, our web sites have electronic security systems in place, including the use of firewalls and data encryption user identifiers, passwords or other access codes may also be used to control access to your personal information.</p>

              <h2>Links to Other Sites</h2>
              <p>You may be able to access external web sites by clicking on links we have provided. Those other web sites are not subject to our privacy standards, policies and procedures. You will need to contact or review those web sites directly to ascertain their privacy standards, policies and procedures.</p>

              <h2>Gaining Access to your Personal Information</h2>
              <p>You can gain access to your personal information. This is subject to some exceptions allowed by law. Factors affecting a right to access include:</p>
              <ul>
                <li>Access would pose a serious threat to the life or health of any individual</li>
                <li>Access would have an unreasonable impact on the privacy of others</li>
                <li>A frivolous or vexatious request</li>
                <li>The information relates to a commercially sensitive decision making process</li>
                <li>Access would be unlawful</li>
                <li>Access would prejudice enforcement activities relating to criminal activities and other breaches of law, public revenue, a security function or negotiations with you</li>
                <li>Legal dispute resolution proceedings</li>
                <li>Denying access is required or authorised by or under law.</li>
              </ul>
              <p>We will give you reasons if we deny access. Contact us to obtain a form requesting access. In some cases we may be able to deal with your request over the telephone or over a counter.</p>

              <h2>Your Sensitive Information</h2>
              <p>Without your consent we will not collect information about you that reveals your racial or ethnic origin, political opinions, religious or philosophical beliefs or affiliations, membership of a professional or trade association, membership of a trade union, details of health, disability, sexual orientation, or criminal record.</p>
              <p>This is subject to some exceptions including:</p>
              <ul>
                <li>The collection is required by law</li>
                <li>When the information is necessary for the establishment, exercise or defense of a legal claim</li>
              </ul>

              <h2>Resolving your Privacy Issues</h2>
              <p>If you have any issues you wish to raise with Ogden's Coaches, or would like to discuss any issues about our Privacy Policy, then please contact:</p>
              <p>General Manager<br/>
              Ogden's Coaches<br/>
              17-23 Burrundulla Rd<br/>
              Mudgee NSW 2850</p>
              
              <h2>For More Information</h2>
              <p>For more information about privacy in general, you can visit the Federal Privacy Commissioner's website <a href="https://www.oaic.gov.au/" target="_blank" rel="noreferrer">www.oaic.gov.au</a></p>
            </Card>
          </Col>
          <Col xs={{span: 24, order: 1}} md={{span: 8, order: 2}}>
            <Card bordered={false} className="CardItem Cover" bodyStyle={{padding: 0}} cover={<img alt="" src={Image1} />}></Card>
          </Col>
        </Row>
      </div>
    </div>
  )
}
