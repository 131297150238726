import React, { useEffect } from 'react';
import { Col, Row, Card } from 'antd';

const Image1 = require('../assets/original/_DA_8653_med.jpg');

export default function Links() {
  useEffect(() => {
    document.body.classList.add("PageLinks");
    return () => {
      document.body.classList.remove("PageLinks");
    };
  }, []);
  return (
    <div className="Links PageSingle" style={{paddingBottom: "50px"}}>
      <section className="PageHero">
        <div className="AppContainer">
          <h1 className="title">Links</h1>
        </div>
      </section>
      <div className="AppContainer">
        <Row gutter={[20, 0]}>
          <Col xs={{span: 24, order: 2}} md={{span: 16, order: 1}}>
            <Card bordered={false} className="CardItem">
              <p>Below you'll find links to handy resources, organisations and information. Please note that Ogden's Coaches is not responsible for any content on external websites.</p>

              <h2>Travelling in NSW</h2>
              <h4 className="mb-0">Safety Town</h4>
              <a href="https://www.safetytown.com.au" target="_blank" className="d-block mb-25" rel="noreferrer">www.safetytown.com.au</a>
              <h4 className="mb-0">Transport for N.S.W.</h4>
              <a href="http://www.transport.nsw.gov.au" target="_blank" className="d-block mb-25" rel="noreferrer">www.transport.nsw.gov.au</a>
              <h4 className="mb-0">Centre for Road Safety</h4>
              <a href="http://roadsafety.transport.nsw.gov.au" target="_blank" className="d-block mb-25" rel="noreferrer">www.roadsafety.transport.nsw.gov.au</a>

              <h4 className="mb-0">Bus Pass Applications</h4>
              <a href="https://apps.transport.nsw.gov.au/ssts" target="_blank" className="d-block mb-25" rel="noreferrer">www.apps.transport.nsw.gov.au/ssts</a>
              <h4 className="mb-0">Train Link</h4>
              <a href="http://www.nswtrainlink.info" target="_blank" className="d-block mb-25" rel="noreferrer">www.nswtrainlink.info</a>
              <h4 className="mb-0">Bus NSW</h4>
              <a href="http://www.busnsw.com.au" target="_blank" className="d-block mb-25" rel="noreferrer">www.busnsw.com.au</a>
              <h4 className="mb-0">131 500 Transport Info Line</h4>
              <a href="http://www.transportnsw.info" target="_blank" className="d-block mb-40" rel="noreferrer">www.transportnsw.info</a>

              <h2>Employment at Ogden's</h2>
              <h4 className="mb-0">Working with Children Check</h4>
              <a href="https://www.kidsguardian.nsw.gov.au/working-with-children/working-with-children-check/apply" target="_blank" className="d-block mb-40" rel="noreferrer">www.kidsguardian.nsw.gov.au/working-with-children/working-with-children-check/apply</a>

              <h2>Website Design & Maintenance</h2>
              <h4 className="mb-0">Busable</h4>
              <a href="https://busable.app" target="_blank" className="d-block mb-25" rel="noreferrer">www.busable.app</a>
            </Card>
          </Col>
          <Col xs={{span: 24, order: 1}} md={{span: 8, order: 2}}>
            <Card bordered={false} className="CardItem Cover" bodyStyle={{padding: 0}} cover={<img alt="" src={Image1} />}></Card>
          </Col>
        </Row>
      </div>
    </div>
  )
}
