import React, { useEffect } from 'react';
import { Col, Row, Card } from 'antd';

const Image1 = require('../assets/original/_DA_8583_med.jpg');

export default function Sitemap() {
  useEffect(() => {
    document.body.classList.add("PageSitemap");
    return () => {
      document.body.classList.remove("PageSitemap");
    };
  }, []);
  return (
    <div className="Sitemap PageSingle" style={{paddingBottom: "50px"}}>
      <section className="PageHero">
        <div className="AppContainer">
          <h1 className="title">Website Map</h1>
        </div>
      </section>
      <div className="AppContainer">
        <Row gutter={[20, 0]}>
          <Col xs={{span: 24, order: 2}} md={{span: 16, order: 1}}>
            <Card bordered={false} className="CardItem">
              <h4 className="mt-0 mb-0">Homepage</h4>
              <a href="/" target="_blank" className="d-block mb-25">Homepage</a>

              <h4 className="mt-0 mb-0">Services</h4>
              <a href="/charters" className="d-block mb-5">Charter Services - hire a bus</a>
              <a href="/about" className="d-block mb-5">Ogden's History & Experience</a>
              <a href="/employment" className="d-block mb-5">Employment with Ogden's Coaches</a>
              <a href="/driver-training" className="d-block mb-25">Driver Training</a>

              <h4 className="mt-0 mb-0">Customer Info</h4>
              <a href="/timetables" target="_blank" className="d-block mb-5">Timetables</a>
              <a href="/trip-planner" target="_blank" className="d-block mb-5">Trip Planner</a>
              <a href="/schools" target="_blank" className="d-block mb-5">Schools</a>
              <a href="/student-travel-guidelines" target="_blank" className="d-block mb-25">Student Travel</a>

              <h4 className="mt-0 mb-0">Events</h4>
              <a href="/events" target="_blank" className="d-block mb-25">Events</a>

              <h4 className="mt-0 mb-0">Contact Us</h4>
              <a href="/contact-us" target="_blank" className="d-block mb-5">Contact Us</a>
              <a href="/feedback" target="_blank" className="d-block mb-25">Feedback & Comments</a>

              <h4 className="mt-0 mb-0">Facebook</h4>
              <a href="https://www.facebook.com/ogdenscoachesmudgee" target="_blank" className="d-block mb-5" rel="noreferrer">Ogden's Mudgee</a>
              <a href="https://www.facebook.com/ogdenscoaches" target="_blank" className="d-block mb-25" rel="noreferrer">Ogden's Central West</a>

              <h4 className="mt-0 mb-0">Information</h4>
              <a href="/policies" target="_blank" className="d-block mb-5">Customer Service Plan</a>
              <a href="/policies" target="_blank" className="d-block mb-5">Environmental Policy</a>
              <a href="/policies" target="_blank" className="d-block mb-5">Accessible Transport Policy</a>
              <a href="/policies" target="_blank" className="d-block mb-5">Passenger Rights & Obligations</a>
              <a href="/privacy" target="_blank" className="d-block mb-5">Privacy Policy</a>
              <a href="/links" target="_blank" className="d-block mb-25">Links</a>
            </Card>
          </Col>
          <Col xs={{span: 24, order: 1}} md={{span: 8, order: 2}}>
            <Card bordered={false} className="CardItem Cover" bodyStyle={{padding: 0}} cover={<img alt="" src={Image1} />}></Card>
          </Col>
        </Row>
      </div>
    </div>
  )
}
