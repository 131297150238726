import React, { useState } from "react";
import { Button, Card, DatePicker, Form, Input, Alert, TimePicker } from "antd";
import StorageService from "../utils/storage";
import { sendMail } from "../utils/mail";

export const CharterForm = () => {
    const [show, setShow] = useState(false);
    const [form] = Form.useForm();

    const onSubmit = async (values) => {
        console.log('Submitting charter with values', values)
        setShow(false);

        const organisation = StorageService.getItem("organisation");
        const to = organisation?.email || 'avindu+wb@busable.app';
        const charterDate = values['charter-date']
        delete values['charter-date']

        await sendMail({
            to,
            from: "Busable <no-reply@busable.app>",
            subject: `New Charter Enquiry - ${values.email}`,
            merge: {
                ...values,
                email_id: values.email,
                fromDate: charterDate?.fromDate?.format("DD-MM-YYYY"),
                fromTime: charterDate?.fromTime?.format("HH:mm"),
                toDate: charterDate?.toDate?.format("DD-MM-YYYY"),
                toTime: charterDate?.toTime?.format("HH:mm"),
            },
            template: 'Charter-v2',
        });
        setShow(true);
        form.resetFields();
    };
    
    const validateCharterDate = (_, value) => {
        const fromDate = form.getFieldValue(['charter-date', 'fromDate']);
        const fromTime = form.getFieldValue(['charter-date', 'fromTime']);
        const toDate = form.getFieldValue(['charter-date', 'toDate']);
        const toTime = form.getFieldValue(['charter-date', 'toTime']);
      
        if (!fromDate || !fromTime || !toDate || !toTime) {
            return Promise.reject(new Error('Please select both date and time for "From" and "To"!'));
        }
        
        return Promise.resolve();
    };

    return (
        <Card bordered={false} className="CardItem mb-25">
            <h2 className="CardTitle mb-40">Booking Enquiry Form</h2>
            {show && (
                <Alert
                    closable
                    onClose={() => setShow(false)}
                    message="Your Charter Request has been sent successfully."
                    type="success"
                    style={{ marginBottom: '30px' }}
                />
            )}
            <Form
                form={form}
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                layout="horizontal"
                size="large"
                style={{ maxWidth: 700 }}
                onFinish={onSubmit}
            >
                <Form.Item 
                    label="Name" 
                    name="name"
                    rules={[{ required: true, message: 'Please input your name!' }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item 
                    label="Email" 
                    name="email"
                    rules={[{ required: true, message: 'Please input your email!' }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item 
                    label="Phone" 
                    name="phone"
                    rules={[{ required: true, message: 'Please input your phone number!' }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Charter Date"
                    name="charter-date"
                    className="form-custom-labels"
                    rules={[{ validator: validateCharterDate }]}
                >
                    <div>
                        <span>From</span>
                        <DatePicker format="DD-MM-YYYY" onChange={(date) => form.setFieldsValue({ 'charter-date': { ...form.getFieldValue('charter-date'), fromDate: date } })}/>
                        <TimePicker format="HH:mm" onChange={(time) => form.setFieldsValue({ 'charter-date': { ...form.getFieldValue('charter-date'), fromTime: time } })}/>
                    </div>
                    <div>
                        <span>To</span>
                        <DatePicker format="DD-MM-YYYY" onChange={(date) => form.setFieldsValue({ 'charter-date': { ...form.getFieldValue('charter-date'), toDate: date } })}/>
                        <TimePicker format="HH:mm" onChange={(time) => form.setFieldsValue({ 'charter-date': { ...form.getFieldValue('charter-date'), toTime: time } })}/>
                    </div>
                </Form.Item>
                <Form.Item 
                    label="Pickup Address" 
                    name="pickup-address"
                    rules={[{ required: true, message: 'Please input the pickup address!' }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item 
                    label="Drop-off Address" 
                    name="drop-off-address"
                    rules={[{ required: true, message: 'Please input the drop-off address!' }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item 
                    label="Comments" 
                    name="comments"
                    rules={[{ required: true, message: 'Please input your comments!' }]}
                >
                    <Input.TextArea />
                </Form.Item>
                <Form.Item wrapperCol={{ md: { offset: 8, span: 16 } }}>
                    <Button htmlType="submit" size="small" className="btn-secondary">
                        Submit
                    </Button>
                </Form.Item>
            </Form>
        </Card>
    );
};

export default CharterForm;