import React, { useEffect, useState } from "react";
import DynamicItems from "../components/DynamicItems";
import { gql, useQuery } from "@apollo/client";
import StorageService from "../utils/storage";
import { postsWithCategoryQuery, postsWithoutCategoryQuery } from "../utils/query";
import ReactGA from "react-ga4";
import { useLocation } from "react-router-dom";

const pageQuery = gql`
    query getPage($id: ID) {
        wbPage(id: $id) {
            data {
                attributes {
                    title
                    type {
                        ... on ComponentLayoutsPosts {
                            title
                            categories {
                                data {
                                    attributes {
                                        name
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;

const postsQuery = gql`
    query getPage($categories: [String]) {
        wbPosts(filters: { categories: { name: { in: $categories } } }) {
            data {
                id
                attributes {
                    title
                    publishedAt
                    summary
                    cover {
                        data {
                            attributes {
                                url
                            }
                        }
                    }
                    categories {
                        data {
                            attributes {
                                name
                            }
                        }
                    }
                }
            }
        }
    }
`;

export default function DynamicList({ pageId, url }) {
    const location = useLocation();
    const [title, setTitle] = useState("");
    const [categories, setCategories] = useState([]);

    const { data: pageData } = useQuery(pageQuery, {
        variables: {
            id: pageId,
        },
    });

    const { error, loading, data } = useQuery(categories.length ? postsWithCategoryQuery : postsWithoutCategoryQuery, {
        variables: {
            categories,
            id: StorageService.getItem("organisationBusableId"),
        },
    });

    useEffect(() => {
        if (pageData) {
            ReactGA.send({ hitType: "pageview", page: location.pathname, title: pageData.wbPage.data.attributes.title });
            const title = pageData.wbPage.data.attributes.title;
            const categories = pageData.wbPage.data.attributes.type[0].categories.data.map((c) => c.attributes.name);
            setCategories(categories);
            setTitle(title);
        }
    }, [pageData]);

    useEffect(() => {
        document.body.classList.add("PageEvents");
        return () => {
            document.body.classList.remove("PageEvents");
        };
    }, []);

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error :(</p>;

    return (
        <div className="Events" style={{ paddingBottom: "50px" }}>
            <section className="PageHero">
                <div className="AppContainer">
                    <h1 className="title">{title}</h1>
                </div>
            </section>
            <div className="AppContainer">
                <DynamicItems data={data} url={url} />
            </div>
        </div>
    );
}
