import React, { useEffect, useState } from "react";
import { Col, Row, Card } from "antd";
import { Helmet } from "react-helmet";
import InfoMenu from "../components/InfoMenu";
import DynamicScript from "../components/DynamicScript";
import EmbedScript from "../components/EmbedScript";
const Image1 = require("../assets/original/_DA_8983_low_res.jpg");

export default function OgdensWestern() {
    const [activeLink, setActiveLink] = useState("tripPlanner");

    useEffect(() => {
        document.body.classList.add("PageOgdensWestern");
        return () => {
            document.body.classList.remove("PageOgdensWestern");
        };
    }, []);

    const showStyle = (link) => {
        console.log(link, activeLink);
        if (link !== activeLink) {
            return { style: { display: "none" } };
        }
        return {};
    };

    return (
        <div className="OgdensWestern PageSingle" style={{ paddingBottom: "50px" }}>
            <section
                className="PageHero Cover"
                style={{ backgroundImage: `url(${Image1})` }}
            >
                <div className="AppContainer">
                    <h1 className="title" style={{ marginBottom: "8px" }}>
                        Ogdens Western
                    </h1>
                    <h2 className="title" style={{ marginBottom: "0" }}>
                        Gilgandra | Gulargambone | Trangie
                    </h2>
                    <InfoMenu activeLink={activeLink} setActiveLink={setActiveLink} />
                </div>
            </section>
            <div className="AppContainer">
                <Row gutter={[20, 0]}>
                    <Col xs={{ span: 24, order: 1 }} md={{ span: 24, order: 1 }}>
                        <Card bordered={false} className="CardEmbed">
                            {/* {activeLink} */}
                            <div id="busable_trip_planner" {...showStyle("tripPlanner")}></div>
                            <div id="busable_school_list" {...showStyle("schoolList")}></div>
                            <div id="busable_timetables" {...showStyle("timetables")}></div>
                            <Helmet>
                                <script src="https://enable.busable.app/busable.embed.js?operator_id=ogdens_western&views=timetables,trip_planner,school_list"></script>
                            </Helmet>
                        </Card>
                    </Col>
                </Row>
            </div>
        </div>
    );
}
