import React, { useEffect, useState } from "react";
import { Button, Col, Row, Alert, Flex } from "antd";
import { useQuery, gql } from "@apollo/client";
import EventItems from "../components/EventItems";
import { getImageURL } from "../utils/images";
import ReactGA from "react-ga4";
import { useLocation } from "react-router-dom";

const pageQuery = gql`
    query getPage($id: ID) {
        wbPage(id: $id) {
            data {
                attributes {
                    title
                    type {
                        ... on ComponentLayoutsHomepage {
                            Hero_Title_Prefix
                            Hero_Title
                            Hero_Description
                            Hero_Image {
                                data {
                                    attributes {
                                        url
                                    }
                                }
                            }
                            Hero_Primary_Button_Text
                            Hero_Primary_Button_URL
                            Hero_Secondary_Button_Text
                            Hero_Secondary_Button_URL
                            Sec_01_Title_Prefix
                            Sec_01_Title
                            Sec_01_Image {
                                data {
                                    attributes {
                                        url
                                    }
                                }
                            }
                            Sec_01_Description
                            Sec_01_Button_Text
                            Sec_01_Button_URL
                            Sec_02_Title_Prefix
                            Sec_02_Title
                            Sec_02_Image {
                                data {
                                    attributes {
                                        url
                                    }
                                }
                            }
                            Sec_02_Description
                            Sec_02_Button_Text
                            Sec_02_Button_URL
                            CTA_Title
                            CTA_Description
                            CTA_Image {
                                data {
                                    attributes {
                                        url
                                    }
                                }
                            }
                            CTA_Button_Text
                            CTA_Button_URL
                            Sec_03_Title_Prefix
                            Sec_03_Title
                            Sec_03_Description
                            Sec_03_Post_Category {
                                data {
                                    id
                                    attributes {
                                        name
                                    }
                                }
                            }
                            Show_Alert
                            Alert_Text
                        }
                    }
                }
            }
        }
    }
`;

export default function Home({ pageId }) {
    const location = useLocation();
    const [page, setPage] = useState(null);
    const { loading, error, data } = useQuery(pageQuery, {
        variables: {
            id: pageId,
        },
    });

    useEffect(() => {
        if (data?.wbPage?.data?.attributes?.type[0]) {
            ReactGA.send({ hitType: "pageview", page: location.pathname, title: data.wbPage.data.attributes.type[0].title });
            setPage(data.wbPage.data.attributes.type[0]);
        }
    }, [data]);

    useEffect(() => {
        document.title = "Ogden's Coaches";
        document.body.classList.add("PageHome");
        return () => {
            document.body.classList.remove("PageHome");
        };
    }, []);

    if (loading || !data || data?.wbHomePages?.data?.length === 0) return <p>Loading...</p>;
    if (error) return <p>Error :(</p>;

    return (
        <div className="Home">
            {page?.Show_Alert && (
                <Alert
                    type="error"
                    message={<div dangerouslySetInnerHTML={{ __html: page?.Alert_Text }}></div>}
                    className="AppAlert"
                    banner
                    closable
                    showIcon={false}
                />
            )}
            <section
                className="SectionHero"
                style={{
                    backgroundImage: `url('${getImageURL(page?.Hero_Image.data?.attributes.url)}')`,
                }}
            >
                <div className="AppContainer">
                    <Row>
                        <Col lg={14}>
                            <div className="HeroTitle">
                                <h2>{page?.Hero_Title_Prefix}</h2>
                                <h1>{page?.Hero_Title}</h1>
                            </div>
                            <div className="HeroDesc">
                                <p dangerouslySetInnerHTML={{ __html: page?.Hero_Description }}></p>
                            </div>
                            <Flex gap="10px" wrap="wrap">
                                <Button href={page?.Hero_Primary_Button_URL} type="primary">
                                    {page?.Hero_Primary_Button_Text}
                                </Button>
                                <Button href={page?.Hero_Secondary_Button_URL}>{page?.Hero_Secondary_Button_Text}</Button>
                            </Flex>
                            <Flex gap="10px" wrap="wrap" style={{marginTop: '10px'}}>
                                <Button href="https://ssts-apply.transport.nsw.gov.au/ApplySSTS/ApplySSTS.html" target="_blank">APPLY FOR A NEW BUS PASS</Button>
                                <Button href="https://ssts-apply.transport.nsw.gov.au/ApplySSTS/UpdateEntitlement.html" target="_blank">UPDATE EXISTING BUS PASS DETAILS</Button>
                            </Flex>
                        </Col>
                        <Col lg={10}></Col>
                    </Row>
                </div>
            </section>
            <section className="SiteSection">
                <div className="AppContainer">
                    <Row gutter={[80, 40]} align="middle">
                        <Col md={12}>
                            <div className="ImageWrap">
                                <img alt="" src={`${getImageURL(page?.Sec_01_Image.data?.attributes.url)}`} width="100%" />
                            </div>
                        </Col>
                        <Col md={12}>
                            <div className="SectionTitle">
                                <h3>{page?.Sec_01_Title_Prefix}</h3>
                                <h2>{page?.Sec_01_Title}</h2>
                            </div>
                            <div className="SectionContent">
                                <div dangerouslySetInnerHTML={{ __html: page?.Sec_01_Description }}></div>
                            </div>
                            <Flex wrap="wrap">
                                <Button href={page?.Sec_01_Button_URL}>{page?.Sec_01_Button_Text}</Button>
                            </Flex>
                        </Col>
                    </Row>
                </div>
            </section>
            <section
                className="SiteSection Dark BottomBg"
                style={{
                    backgroundImage: `url('${getImageURL(page?.Sec_02_Image.data?.attributes.url)}')`,
                }}
            >
                <div className="AppContainer">
                    <Row gutter={[80, 40]} align="middle">
                        <Col md={12}>
                            <div className="SectionTitle">
                                <h3>{page?.Sec_02_Title_Prefix}</h3>
                                <h2>{page?.Sec_02_Title}</h2>
                            </div>
                            <div className="SectionContent">
                                <div dangerouslySetInnerHTML={{ __html: page?.Sec_02_Description }}></div>
                            </div>
                            <Flex wrap="wrap">
                                <Button href={page?.Sec_02_Button_URL}>{page?.Sec_02_Button_Text}</Button>
                            </Flex>
                        </Col>
                        <Col md={12}></Col>
                    </Row>
                </div>
            </section>
            <section className="SiteSection Events" style={{ paddingTop: 0 }}>
                <div className="AppContainer">
                    <div className="SectionTitle">
                        <h3>{page?.Sec_03_Title_Prefix}</h3>
                        <h2>{page?.Sec_03_Title}</h2>
                        <p>{page?.Sec_03_Description}</p>
                    </div>
                    <EventItems category={page?.Sec_03_Post_Category?.data?.id} />
                </div>
            </section>
            <section
                className="SiteSection Dark CTA"
                style={{
                    backgroundImage: `url('${getImageURL(page?.CTA_Image.data?.attributes.url)}')`,
                }}
            >
                <div className="AppContainer">
                    <div className="SectionTitle">
                        <h2>{page?.CTA_Title}</h2>
                        <p>{page?.CTA_Description}</p>
                        {page?.CTA_Button_Text && (
                            <Flex justify="center" wrap="wrap">
                                <Button href={page?.CTA_Button_URL} target="_blank">{page?.CTA_Button_Text}</Button>
                            </Flex>
                        )}
                    </div>
                </div>
            </section>
        </div>
    );
}
