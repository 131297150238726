import React, { useEffect } from "react";
import { Col, Row, Card } from "antd";
import { useParams } from "react-router-dom";
import { useQuery, gql } from "@apollo/client";
import ReactMarkdown from "react-markdown";
import { getImageURL } from "../utils/images";
import ReactGA from "react-ga4";
import { useLocation } from "react-router-dom";

const Event = gql`
    query GetEvent($id: ID!) {
        wbEvent(id: $id) {
            data {
                id
                attributes {
                    Title
                    publishedAt
                    Description
                    Cover {
                        data {
                            attributes {
                                url
                            }
                        }
                    }
                }
            }
        }
    }
`;

export default function EventsSingle() {
    const location = useLocation();
    const { id } = useParams();
    const { loading, error, data } = useQuery(Event, {
        variables: { id: id },
    });

    useEffect(() => {
        document.body.classList.add("PageEvent");
        return () => {
            document.body.classList.remove("PageEvent");
        };
    }, []);

    useEffect(() => {
        if(data?.wbEvent?.data?.attributes?.Title) 
            ReactGA.send({ hitType: "pageview", page: location.pathname, title: data.wbEvent.data.attributes.Title })
    }, [data])

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error :(</p>;

    return (
        <div className="EventsSingle PageSingle">
            <section className="PageHero">
                <div className="AppContainer">
                    <h2 className="title">Events</h2>
                    <h1 className="title">{data.wbEvent.data.attributes.Title}</h1>
                    <div className="CardMeta">
                        <time>
                            {new Date(data.wbEvent.data.attributes.publishedAt).toLocaleDateString("en-gb", {
                                day: "numeric",
                                month: "long",
                                year: "numeric",
                                hour: "2-digit",
                                minute: "2-digit",
                                hour12: true,
                            })}
                        </time>
                    </div>
                </div>
            </section>
            <div className="AppContainer">
                <Row gutter={[20, 0]}>
                    <Col xs={{ span: 24, order: 2 }} md={{ span: 16, order: 1 }}>
                        <Card bordered={false} className="CardItem">
                            <div dangerouslySetInnerHTML={{ __html: data.wbEvent.data.attributes.Description }}></div>
                        </Card>
                    </Col>
                    <Col xs={{ span: 24, order: 1 }} md={{ span: 8, order: 2 }}>
                        <Card
                            bordered={false}
                            className="CardItem Cover"
                            bodyStyle={{ padding: 0 }}
                            cover={<img alt="" src={`${getImageURL(data.wbEvent.data.attributes.Cover.data?.attributes.url)}`} />}
                        ></Card>
                    </Col>
                </Row>
            </div>
        </div>
    );
}
