import React, { useEffect } from 'react';
import { Col, Row, Card } from 'antd';
import { Link } from 'react-router-dom';
//import { ReactComponent as Region } from "../assets/icons/Region.svg";

export default function Regions() {
  const pagesToHardNavigate = [
    "/ogdens_orana",
    "/ogdens_western",
    "/ogdens_mudgee",
    "/ogdens_condobolin",
  ];

  const handleNavigation = (url) => {
    window.location.href = url;
  };

  useEffect(() => {
    document.body.classList.add("PageRegions");
    return () => {
      document.body.classList.remove("PageRegions");
    };
  }, []);

  const createLink = (url, name, description) => {
    const isHardNavigate = pagesToHardNavigate.includes(url.split('?')[0]);
    return isHardNavigate ? (
      <a href={url} className="CardRegion" onClick={(e) => { e.preventDefault(); handleNavigation(url); }}>
        <Card hoverable bordered={false} className="CardItem">
          {/* <Region/> */}
          <h2>{name}</h2>
          <p>{description}</p>
        </Card>
      </a>
    ) : (
      <Link to={url} className="CardRegion">
        <Card hoverable bordered={false} className="CardItem">
          {/* <Region/> */}
          <h2>{name}</h2>
          <p>{description}</p>
        </Card>
      </Link>
    );
  };

  return (
    <div className="Regions PageSingle" style={{paddingBottom: "20px"}}>
      <section className="PageHero">
        <div className="AppContainer">
          <h1 className="title">Select your Region</h1>
        </div>
      </section>
      <div className="AppContainer" style={{maxWidth: '1000px'}}>
        <Row gutter={[20, 20]}>
          <Col xs={24} md={12}>
            {createLink("/ogdens_orana?reg=true", "Ogdens Orana", "Dubbo | Wellington | Narromine")}
          </Col>
          <Col xs={24} md={12}>
            {createLink("/ogdens_western?reg=true", "Ogdens Western", "Gilgandra | Gulargambone | Trangie")}
          </Col>
          <Col xs={24} md={12}>
            {createLink("/ogdens_mudgee?reg=true", "Ogdens Mudgee", "Mudgee | Gulgong | Goolma")}
          </Col>
          <Col xs={24} md={12}>
            {createLink("/ogdens_condobolin?reg=true", "Ogdens Condobolin", "Condobolin")}
          </Col>
        </Row>
      </div>
    </div>
  )
}