import React, { useEffect, useState } from "react";
import { Col, Row, Card } from "antd";
import ReactMarkdown from "react-markdown";
import { useQuery, gql } from "@apollo/client";
import { getImageURL } from "../utils/images";
import ReactGA from "react-ga4";
import { useLocation } from "react-router-dom";

const pageQuery = gql`
    query getPage($id: ID) {
        wbPage(id: $id) {
            data {
                attributes {
                    title
                    type {
                        ... on ComponentLayoutsTwoColumn {
                            prefixTitle
                            title
                            suffixTitle
                            sections {
                                content
                            }
                            image {
                                data {
                                    attributes {
                                        url
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;

export default function TwoColumn({ pageId }) {
    const location = useLocation();
    const [page, setPage] = useState(null);
    const { loading, error, data } = useQuery(pageQuery, {
        variables: {
            id: pageId,
        },
    });

    useEffect(() => {
        if (data?.wbPage?.data?.attributes?.type[0]) {
            ReactGA.send({ hitType: "pageview", page: location.pathname, title: data.wbPage.data.attributes.type[0].title });
            setPage(data.wbPage.data.attributes.type[0]);
        }
    }, [data]);

    useEffect(() => {
        document.body.classList.add("PageAbout");
        return () => {
            document.body.classList.remove("PageAbout");
        };
    }, []);

    if (loading || !page) return <p>Loading...</p>;
    if (error) return <p>Error :(</p>;

    return (
        <div className="About PageSingle">
            <section className="PageHero">
                <div className="AppContainer">
                    <h2 className="title">{page.titlePrefix}</h2>
                    <h1 className="title">{page.title}</h1>
                </div>
            </section>
            <div className="AppContainer">
                <Row gutter={[20, 0]}>
                    <Col xs={{ span: 24, order: 2 }} md={{ span: 16, order: 1 }}>
                        {page.sections.map((section, index) => (
                            <Card bordered={false} className="CardItem" key={index}>
                                <div dangerouslySetInnerHTML={{ __html: section.content }}></div>
                            </Card>
                        ))}
                    </Col>
                    <Col xs={{ span: 24, order: 1 }} md={{ span: 8, order: 2 }}>
                        {page.image.data.map((image, index) => (
                            <Card
                                key={index}
                                bordered={false}
                                className="CardItem Cover"
                                bodyStyle={{ padding: 0 }}
                                cover={<img alt="" src={getImageURL(image.attributes.url)} />}
                            ></Card>
                        ))}
                    </Col>
                </Row>
            </div>
        </div>
    );
}
