import { gql } from "@apollo/client";

export const headerMenuQuery = gql`
    query getMenu($id: String) {
        wbMenus(filters: { organisation: { orgId: { eq: $id } }, type: { eq: "header" } }) {
            data {
                id
                attributes {
                    menu {
                        ... on ComponentWbMenusMenu {
                            name
                            url
                        }
                        ... on ComponentWbMenusSubmenu {
                            name
                            url
                            menu {
                                name
                                url
                                menu {
                                    name
                                    url
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;

export const footerMenuQuery = gql`
    query getMenu($id: String) {
        wbMenus(filters: { organisation: { orgId: { eq: $id } }, type: { eq: "footer" } }) {
            data {
                id
                attributes {
                    menu {
                        ... on ComponentWbMenusMenu {
                            id
                            name
                            url
                        }
                        ... on ComponentWbMenusSubmenu {
                            id
                            name
                            hideName
                            url
                            menu {
                                id
                                name
                                url
                                menu {
                                    id
                                    name
                                    url
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;

export const EventsWithCategory = gql`
    query GetEvents($id: String, $categoryId: ID) {
        wbPosts(filters: { organisation: { orgId: { eq: $id } }, categories: { id: { eq: $categoryId } } }) {
            data {
                id
                attributes {
                    title
                    publishedDate
                    summary
                    cover {
                        data {
                            attributes {
                                url
                            }
                        }
                    }
                }
            }
        }
    }
`;

export const EventsWithoutCategory = gql`
    query GetEvents($id: String) {
        wbPosts(filters: { organisation: { orgId: { eq: $id } } }) {
            data {
                id
                attributes {
                    title
                    publishedDate
                    summary
                    cover {
                        data {
                            attributes {
                                url
                            }
                        }
                    }
                }
            }
        }
    }
`;

export const postsWithoutCategoryQuery = gql`
    query getPage($id: String) {
        wbPosts(filters: { organisation: { orgId: { eq: $id } } }) {
            data {
                id
                attributes {
                    title
                    publishedAt
                    summary
                    cover {
                        data {
                            attributes {
                                url
                            }
                        }
                    }
                    categories {
                        data {
                            attributes {
                                name
                            }
                        }
                    }
                }
            }
        }
    }
`;

export const postsWithCategoryQuery = gql`
    query getPage($categories: [String]) {
        wbPosts(filters: { categories: { name: { in: $categories } } }) {
            data {
                id
                attributes {
                    title
                    publishedAt
                    summary
                    cover {
                        data {
                            attributes {
                                url
                            }
                        }
                    }
                    categories {
                        data {
                            attributes {
                                name
                            }
                        }
                    }
                }
            }
        }
    }
`;
