import React, { useEffect, useState } from "react";
import { Col, Row, Card, Button } from "antd";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import InfoMenu from "../components/InfoMenu";
import DynamicScript from "../components/DynamicScript";
import EmbedScript from "../components/EmbedScript";
import { ReactComponent as Download } from "../assets/icons/Download.svg";
const Image1 = require("../assets/original/_DA_8616_low_res.jpg");

export default function OgdensOrana() {
    const [activeLink, setActiveLink] = useState("tripPlanner");
    const [isTownlink, setisTownlink] = useState(false);
    const [townLink, setTownLink] = useState();

    useEffect(() => {
        document.body.classList.add("PageOgdensOrana");
        return () => {
            document.body.classList.remove("PageOgdensOrana");
        };
    }, []);

    useEffect(() => {
        // Parse the query parameters
        const queryParams = new URLSearchParams(window.location.search);
        // Check if 'timetables' query parameter is present and set to 'true'
        if (queryParams?.get('timetables') === 'true') {
          setActiveLink('timetables');
        }
        if (queryParams?.get('townlink')) {
          setisTownlink(true);
          setTownLink(queryParams?.get('townlink'));
        }
    }, []);

    const showStyle = (link) => {
        console.log(link, activeLink);
        if (link !== activeLink) {
            return { style: { display: "none" } };
        }
        return {};
    };

    return (
        <div className="OgdensOrana PageSingle" style={{ paddingBottom: "50px" }}>
            {isTownlink ?
                <section
                    className="PageHero Cover"
                    style={{ backgroundImage: `url(${Image1})` }}
                >
                    <div className="AppContainer">
                        <h1 className="title" style={{ marginBottom: "8px" }}>
                            {townLink}
                        </h1>
                        <Button href="/town-link" size="small" className="mt-20 light" type="primary">Back to Town-link</Button>
                        <div className="InfoMenu">
                            <ul>
                                <li style={{borderRadius: '18px'}}><Link to="https://lms-prod-media-assets.s3.ap-southeast-2.amazonaws.com/2023_16_cities_update_OGDENS_townlink_tt_Tf_NSW_v2_5ad18239df.pdf" target="_blank"><Download /> Download Timetable</Link></li>
                            </ul>
                        </div>
                    </div>
                </section>
            :
                <section
                    className="PageHero Cover"
                    style={{ backgroundImage: `url(${Image1})` }}
                >
                    <div className="AppContainer">
                        <h1 className="title" style={{ marginBottom: "8px" }}>
                            Ogdens Orana
                        </h1>
                        <h2 className="title" style={{ marginBottom: "0" }}>
                            Dubbo | Wellington | Narromine
                        </h2>
                        <InfoMenu activeLink={activeLink} setActiveLink={setActiveLink} />
                    </div>
                </section>
            }
            <div className="AppContainer">
                <Row gutter={[20, 0]}>
                    <Col xs={{ span: 24, order: 1 }} md={{ span: 24, order: 1 }}>
                        <Card bordered={false} className="CardEmbed">
                            {/* {activeLink} */}
                            <div id="busable_trip_planner" {...showStyle("tripPlanner")}></div>
                            <div id="busable_school_list" {...showStyle("schoolList")}></div>
                            <div id="busable_timetables" {...showStyle("timetables")}></div>
                            {/* <EmbedScript src="https://enable.busable.app/busable.embed.js?operator_id=ogdens_orana&views=trip_planner,school_list,timetables" /> */}
                            <Helmet>
                                <script src="https://enable.busable.app/busable.embed.js?operator_id=ogdens_orana&views=timetables,trip_planner,school_list"></script>
                            </Helmet>
                        </Card>
                    </Col>
                </Row>
            </div>
        </div>
    );
}
