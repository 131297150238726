import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import StorageService from "../utils/storage";

export default function NotFound() {
    // Enable if we want to redirect users back to home
    // const navigate = useNavigate();
    // useEffect(() => {
    //     const organisation = StorageService.getItem("organisation");
    //     if (organisation) {
    //         navigate("/");
    //     }
    // }, []);

    return (
        <div className="NotFound PageSingle">
            <section className="AppContainer">
                <h1>404</h1>
                <p>Page not found</p>
            </section>
        </div>
    );
}
