import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const TimetablesRedirect = () => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const pathParts = location?.pathname?.split('/');
    if (pathParts?.length > 2 && pathParts?.[1] === 'timetables') {
      const dynamicField = pathParts[2];
      const searchParams = new URLSearchParams(location.search);
      searchParams.set('timetables', 'true');

      navigate(`/${dynamicField}?${searchParams.toString()}`);
    }
  }, [location, navigate]);

  return null; // Or a loading spinner while the redirection happens
};

export default TimetablesRedirect;
