import React, { useEffect, useState } from "react";
import { Col, Row, Card, Button, Form, Input, Select } from "antd";
import { gql, useQuery } from "@apollo/client";
import { getImageURL } from "../utils/images";
import { FormSelector } from "../components/FormSelector";
import ReactGA from "react-ga4";
import { useLocation } from "react-router-dom";

const pageQuery = gql`
    query getPage($id: ID) {
        wbPage(id: $id) {
            data {
                attributes {
                    title
                    type {
                        ... on ComponentLayoutsTwoColumnWithFormAndSidebar {
                            prefixTitle
                            title
                            suffixTitle
                            sections {
                                content
                            }
                            form
                            sidebar
                            image {
                                data {
                                    attributes {
                                        url
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;

export default function TwoColumnWithFormAndSidebar({ pageId }) {
    const location = useLocation();
    const [page, setPage] = useState(null);
    const { loading, error, data } = useQuery(pageQuery, {
        variables: {
            id: pageId,
        },
    });

    useEffect(() => {
        if (data?.wbPage?.data?.attributes?.type[0]) {
            ReactGA.send({ hitType: "pageview", page: location.pathname, title: data.wbPage.data.attributes.type[0].title });
            setPage(data.wbPage.data.attributes.type[0]);
        }
    }, [data]);

    useEffect(() => {
        document.body.classList.add("PageFeedback");
        return () => {
            document.body.classList.remove("PageFeedback");
        };
    }, []);

    if (loading || !page) return <p>Loading...</p>;
    if (error) return <p>Error :(</p>;

    return (
        <div className="Feedback PageSingle" style={{ paddingBottom: "50px" }}>
            <section className="PageHero">
                <div className="AppContainer">
                    <h1 className="title">{page.title}</h1>
                </div>
            </section>
            <div className="AppContainer">
                <Row gutter={[20, 0]}>
                    <Col xs={{ span: 24, order: 1 }} md={{ span: 16, order: 1 }}>
                        {page.sections.map((section, index) => (
                            <React.Fragment key={index}>
                                <Card bordered={false} className="CardItem mb-25">
                                    <div dangerouslySetInnerHTML={{ __html: section.content }}></div>
                                </Card>
                                {/* Hardcoded logic for charter form ogdens */}
                                {page.form === "charter" && index === 0 && <FormSelector form={page.form} />}
                            </React.Fragment>
                        ))}
                        {page.form !== "charter" && <FormSelector form={page.form} />}
                    </Col>
                    <Col xs={{ span: 24, order: 2 }} md={{ span: 8, order: 2 }}>
                        {page.image.data.map((image, index) => (
                            <Card
                                key={index}
                                bordered={false}
                                className="CardItem Cover"
                                bodyStyle={{ padding: 0 }}
                                cover={<img alt="" src={getImageURL(image.attributes.url)} />}
                            ></Card>
                        ))}
                        <Card bordered={false} className="CardItem">
                            <div dangerouslySetInnerHTML={{ __html: page.sidebar }}></div>
                        </Card>
                    </Col>
                </Row>
            </div>
        </div>
    );
}
