import axios from "axios";

export const sendMail = async ({ to, from, subject, merge, template }) => {
    return axios.post(
        "https://api.elasticemail.com/v4/emails/transactional",
        {
            Recipients: {
                To: [to],
            },
            Content: {
                From: from,
                Subject: subject,
                Merge: merge,
                TemplateName: template
            },
        },
        {
            headers: {
                "X-ElasticEmail-ApiKey": "EFDA288C1FDDD78446AA5C2DE2F5521E15E2B011B988FFDADC68056155EFD00745ABF7730FB2F40D4644E3262B05C9FE",
            },
        }
    );
};
