import { CharterForm } from "./CharterForm";
import { ContactForm } from "./ContactForm";

export const FormSelector = ({form}) => {
    switch (form) {
        case "contact_us":
            return <ContactForm />;
        case "charter":
            return <CharterForm />;
        default:
            return null;
    }
};
