import React, { useEffect, useState } from "react";
import { Col, Row, Card } from "antd";
import { gql, useQuery } from "@apollo/client";
import ReactGA from "react-ga4";
import { useLocation } from "react-router-dom";

const pageQuery = gql`
    query getPage($id: ID) {
        wbPage(id: $id) {
            data {
                attributes {
                    title
                    type {
                        ... on ComponentLayoutsOneColumnWithMap {
                            prefixTitle
                            title
                            suffixTitle
                            sections {
                                content
                                map
                            }
                        }
                    }
                }
            }
        }
    }
`;

export default function OneColumnWithMap({ pageId }) {
    const location = useLocation();
    const [page, setPage] = useState(null);
    const { loading, error, data } = useQuery(pageQuery, {
        variables: {
            id: pageId,
        },
    });

    useEffect(() => {
        if (data?.wbPage?.data?.attributes?.type[0]) {
            ReactGA.send({ hitType: "pageview", page: location.pathname, title: data.wbPage.data.attributes.type[0].title });
            setPage(data.wbPage.data.attributes.type[0]);
        }
    }, [data]);

    useEffect(() => {
        document.body.classList.add("PageContactUs");
        return () => {
            document.body.classList.remove("PageContactUs");
        };
    }, []);

    if (loading || !page) return <p>Loading...</p>;
    if (error) return <p>Error :(</p>;

    return (
        <div className="ContactUs PageSingle" style={{ paddingBottom: "50px" }}>
            <section className="PageHero">
                <div className="AppContainer">
                    <h1 className="title mb-8">Contact Us</h1>
                    <h2 className="title mb-0">We'd love to hear from you!</h2>
                </div>
            </section>
            <div className="AppContainer">
                {page.sections.map((section, index) => (
                    <Card bordered={false} className="CardItem mb-25">
                        <Row gutter={[20, 0]}>
                            <Col xs={{ span: 24, order: 1 }} md={{ span: 12, order: 1 }}>
                                <div dangerouslySetInnerHTML={{ __html: section.content }}></div>
                            </Col>
                            <Col xs={{ span: 24, order: 2 }} md={{ span: 12, order: 2 }}>
                                <iframe
                                    src={section.map}
                                    title={`Map ${index}`} // Add a unique title property
                                    width="600"
                                    height="350"
                                    style={{ border: 0 }}
                                    allowFullScreen=""
                                    loading="lazy"
                                    referrerPolicy="no-referrer-when-downgrade"
                                ></iframe>
                            </Col>
                        </Row>
                    </Card>
                ))}
            </div>
        </div>
    );
}
