import React, { useEffect } from 'react';
import { Col, Row, Card } from 'antd';
import { Link } from 'react-router-dom';
//import { ReactComponent as ITownlink } from "../assets/icons/Townlink.svg";

export default function Townlink() {
  const pagesToHardNavigate = [
    "/ogdens_orana",
    "/ogdens_western",
    "/ogdens_mudgee",
    "/ogdens_condobolin",
  ];

  const handleNavigation = (url) => {
    window.location.href = url;
  };

  useEffect(() => {
    document.body.classList.add("PageTownlink");
    return () => {
      document.body.classList.remove("PageTownlink");
    };
  }, []);

  const createLink = (url, name, description) => {
    const isHardNavigate = pagesToHardNavigate.includes(url.split('?')[0]);
    return isHardNavigate ? (
      <a href={url} className="CardRegion" onClick={(e) => { e.preventDefault(); handleNavigation(url); }}>
        <Card hoverable bordered={false} className="CardItem">
          {/* <ITownlink/> */}
          <h2>{name}</h2>
          {/* <p>{description}</p> */}
        </Card>
      </a>
    ) : (
      <Link to={url} className="CardRegion">
        <Card hoverable bordered={false} className="CardItem">
          {/* <ITownlink/> */}
          <h2>{name}</h2>
          {/* <p>{description}</p> */}
        </Card>
      </Link>
    );
  };

  return (
    <div className="Townlink PageSingle" style={{paddingBottom: "20px"}}>
      <section className="PageHero">
        <div className="AppContainer">
          <h1 className="title">Select your Town-link Service</h1>
        </div>
      </section>
      <div className="AppContainer" style={{maxWidth: '1000px'}}>
        <h2 className="SectionTitle" style={{width: '100%', textAlign: 'center', marginTop: '25px'}}>Dubbo - Narromine - Wellington</h2>
        <Row gutter={[20, 20]}>
          <Col xs={24} md={8}>
            {createLink("/ogdens_orana?townlink=Dubbo to Narromine&b_fr=poi%7CDubbo%2C%20New%20South%20Wales%2C%20Australia%7C-32.247124%2C148.60132&b_to=poi%7CNarromine%2C%20New%20South%20Wales%2C%20Australia%7C-32.232838%2C148.23946&b_ti=now%7C2024-07-04T10%3A00%3A57.721Z&b_st=walk%7C10&b_en=walk%7C10&b_so=Least%20Walking", "Dubbo to Narromine")}
          </Col>
          <Col xs={24} md={8}>
            {createLink("/ogdens_orana?townlink=Dubbo to Wellington&b_fr=poi%7CDubbo%2C%20New%20South%20Wales%2C%20Australia%7C-32.247124%2C148.60132&b_to=poi%7CWellington%2C%20New%20South%20Wales%2C%20Australia%7C-32.554836%2C148.95255&b_ti=now%7C2024-07-04T10%3A46%3A10.841Z&b_st=walk%7C10&b_en=walk%7C10&b_so=Least%20Walking", "Dubbo to Wellington")}
          </Col>
          <Col xs={24} md={8}>
            {createLink("/ogdens_orana?townlink=Narromine to Dubbo&b_fr=poi%7CNarromine%2C%20New%20South%20Wales%2C%20Australia%7C-32.232838%2C148.23946&b_to=poi%7CDubbo%2C%20New%20South%20Wales%2C%20Australia%7C-32.247124%2C148.60132&b_ti=now%7C2024-07-04T10%3A46%3A10.841Z&b_st=walk%7C10&b_en=walk%7C10&b_so=Least%20Walking", "Narromine to Dubbo")}
          </Col>
          <Col xs={24} md={8}>
            {createLink("/ogdens_orana?townlink=Narromine to Wellington&b_fr=poi%7CNarromine%2C%20New%20South%20Wales%2C%20Australia%7C-32.232838%2C148.23946&b_to=poi%7CWellington%2C%20New%20South%20Wales%2C%20Australia%7C-32.554836%2C148.95255&b_ti=now%7C2024-07-04T10%3A46%3A10.841Z&b_st=walk%7C10&b_en=walk%7C10&b_so=Least%20Walking", "Narromine to Wellington")}
          </Col>
          <Col xs={24} md={8}>
            {createLink("/ogdens_orana?townlink=Wellington to Dubbo&b_fr=poi%7CWellington%2C%20New%20South%20Wales%2C%20Australia%7C-32.554836%2C148.95255&b_to=poi%7CDubbo%2C%20New%20South%20Wales%2C%20Australia%7C-32.247124%2C148.60132&b_ti=now%7C2024-07-04T10%3A46%3A10.841Z&b_st=walk%7C10&b_en=walk%7C10&b_so=Least%20Walking", "Wellington to Dubbo")}
          </Col>
          <Col xs={24} md={8}>
            {createLink("/ogdens_orana?townlink=Wellington to Narromine&b_fr=poi%7CWellington%2C%20New%20South%20Wales%2C%20Australia%7C-32.554836%2C148.95255&b_to=poi%7CNarromine%2C%20New%20South%20Wales%2C%20Australia%7C-32.232838%2C148.23946&b_ti=now%7C2024-07-04T10%3A46%3A10.841Z&b_st=walk%7C10&b_en=walk%7C10&b_so=Least%20Walking", "Wellington to Narromine")}
          </Col>
        </Row>

        <h2 className="SectionTitle" style={{width: '100%', textAlign: 'center', marginTop: '100px'}}>Mudgee North - South - East - West</h2>
        <Row gutter={[20, 20]} style={{marginBottom: '50px'}}>
          <Col xs={24} md={12}>
            {createLink("/ogdens_mudgee?rs=01GT89WKVDNN480KETMJEZ6J87&townlink=Mudgee East loop (560)&timetables=true", "Mudgee East loop (560)")}
          </Col>
          <Col xs={24} md={12}>
            {createLink("/ogdens_mudgee?rs=01GSY0KFVDGQ59EMSWC990W8FW&townlink=Mudgee West loop (561)&timetables=true", "Mudgee West loop (561)")}
          </Col>
          <Col xs={24} md={12}>
            {createLink("/ogdens_mudgee?rs=01GSXZPQ6STHS51F81R780E6XM&townlink=Mudgee South loop (562)&timetables=true", "Mudgee South loop (562)")}
          </Col>
          <Col xs={24} md={12}>
            {createLink("/ogdens_mudgee?rs=01GT87EAXTN471GAY7G0GG5FKX&townlink=Mudgee North loop (563)&timetables=true", "Mudgee North loop (563)")}
          </Col>
        </Row>
      </div>
    </div>
  )
}