import React from "react";
import { Button } from "antd";
import { Link } from "react-router-dom";
import "./InfoMenu.css";
import { ReactComponent as TripPlanner } from "../assets/icons/TripPlanner.svg";
import { ReactComponent as School } from "../assets/icons/School.svg";
import { ReactComponent as Timetable } from "../assets/icons/Timetable.svg";

export default function InfoMenu({ activeLink, setActiveLink }) {
    const onMenuClick = (link) => {
        setActiveLink(link);
    };
    return (
        <>
        <Button href="/regions" size="small" className="mt-20 light" type="primary">Back to Regions</Button>
        <div className="InfoMenu">
            <ul>
                {/* <li className="active"><Link to="/ogdens_orana/trip_planner"><TripPlanner /> Trip Planner</Link></li>
				<li><Link to="/ogdens_orana/school_list"><School /> School List</Link></li>
				<li><Link to="/ogdens_orana/timetables"><Timetable /> Timetables</Link></li> */}

                <li onClick={() => onMenuClick("tripPlanner")} className={activeLink === "tripPlanner" ? "active" : null}>
                    <TripPlanner /> Trip Planner
                </li>
                <li onClick={() => onMenuClick("schoolList")} className={activeLink === "schoolList" ? "active" : null}>
                    <School /> School Timetables
                </li>
                <li onClick={() => onMenuClick("timetables")} className={activeLink === "timetables" ? "active" : null}>
                    <Timetable /> Passenger Timetables
                </li>
            </ul>
        </div>
        </>
    );
}
